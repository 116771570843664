import useTheme from 'hooks/useTheme'

function Polygon() {
  const theme = useTheme()
  return (
    <svg width="100%" viewBox="0 0 164.55 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0)">
        <path
          d="M68.4617 17.8303C69.4098 18.4042 70.2121 19.1932 70.7226 20.2691C71.3061 21.3451 71.5978 22.6362 71.5978 24.0708C71.5978 25.5054 71.3061 26.7248 70.6497 27.8008C70.0662 28.8767 69.191 29.6658 68.097 30.1679C67.003 30.7417 65.6902 31.0286 64.3045 31.0286C63.5022 31.0286 62.7729 30.8852 62.0436 30.5983C61.3142 30.3831 60.6578 29.9527 60.1473 29.4506V36.9105H55.9172V17.2565H59.5638L59.9285 18.8345C61.533 17.5434 63.2834 16.8978 65.1068 16.8978C66.3466 16.8978 67.5136 17.1847 68.4617 17.8303ZM66.1278 26.7248C66.8572 26.0793 67.2218 25.2185 67.2218 24.1426C67.2218 23.0666 66.8572 22.2058 66.2008 21.4885C65.5444 20.7712 64.6692 20.4843 63.721 20.4843C62.9188 20.4843 62.1894 20.6278 61.606 20.9864C61.0225 21.2734 60.5849 21.7755 60.2202 22.421V25.6489C60.439 26.2945 60.8766 26.7966 61.3872 27.1552C61.9706 27.5139 62.627 27.7291 63.4293 27.7291C64.5233 27.7291 65.3985 27.3704 66.1278 26.7248Z"
          fill={theme.text16}
        />
        <path
          d="M74.0775 20.3393C74.7339 19.3351 75.6091 18.4743 76.7761 17.9005C77.943 17.3267 79.2558 16.968 80.7144 16.968C82.1731 16.968 83.4859 17.2549 84.6528 17.9005C85.7468 18.4743 86.622 19.3351 87.2784 20.4111C87.9348 21.487 88.2266 22.6347 88.2266 23.9976C88.2266 25.3604 87.9348 26.5081 87.2784 27.584C86.622 28.66 85.8198 29.5208 84.6528 30.0946C83.5588 30.6684 82.1731 31.0271 80.7144 31.0271C79.2558 31.0271 77.943 30.7402 76.7761 30.1663C75.6091 29.5925 74.7339 28.8035 74.0775 27.7275C73.4941 26.6516 73.1294 25.4321 73.1294 23.9976C73.1294 22.6347 73.4941 21.4153 74.0775 20.3393ZM78.3806 26.5798C78.964 27.2971 79.7663 27.584 80.7144 27.584C81.6626 27.584 82.4648 27.2254 83.0483 26.5798C83.6318 25.9343 83.9235 25.0018 83.9235 23.9976C83.9235 22.9216 83.6318 22.0608 83.0483 21.4153C82.3919 20.698 81.6626 20.3393 80.6415 20.3393C79.6934 20.3393 78.964 20.698 78.3076 21.3435C77.7242 22.0608 77.4325 22.9216 77.4325 23.9976C77.4325 25.0018 77.7242 25.8625 78.3806 26.5798Z"
          fill={theme.text16}
        />
        <path d="M95.0822 7.07007V30.6692H90.8521V7.07007H95.0822Z" fill={theme.text16} />
        <path
          d="M112.44 17.6141L103.834 36.9812H99.75L102.959 30.0234L96.8327 17.6141H101.646L105.147 25.361L108.648 17.6141H112.44Z"
          fill={theme.text16}
        />
        <path
          d="M128.632 17.6136V30.6684C128.632 31.8878 128.267 33.0355 127.538 34.0397C126.808 35.044 125.86 35.833 124.62 36.4068C123.38 36.9807 122.068 37.2676 120.609 37.2676C119.515 37.2676 118.567 37.1241 117.765 36.9089C116.962 36.622 116.306 36.3351 115.795 36.0482C115.212 35.6895 114.628 35.2592 113.972 34.6853L116.452 31.8161C117.035 32.39 117.692 32.8203 118.275 33.1073C118.859 33.3942 119.661 33.6094 120.536 33.6094C121.63 33.6094 122.505 33.3224 123.235 32.8203C123.964 32.3182 124.329 31.5292 124.329 30.5967V28.7317C123.891 29.3773 123.307 29.9511 122.432 30.3815C121.557 30.8119 120.536 31.0271 119.369 31.0271C118.202 31.0271 117.035 30.7402 116.014 30.0946C114.993 29.5208 114.191 28.66 113.534 27.584C112.951 26.5081 112.586 25.3604 112.586 24.0693C112.586 22.7781 112.878 21.5587 113.534 20.4828C114.191 19.4068 114.993 18.5461 116.087 17.9005C117.108 17.2549 118.202 16.968 119.369 16.968C120.463 16.968 121.411 17.1115 122.286 17.3984C123.162 17.6853 123.818 18.1157 124.256 18.6178L124.693 17.6136H128.632ZM124.329 25.6473V22.4195C124.037 21.7739 123.599 21.2718 122.943 20.9132C122.286 20.5545 121.557 20.3393 120.682 20.3393C119.661 20.3393 118.786 20.698 118.056 21.3435C117.327 21.9891 116.962 22.8499 116.962 23.9258C116.962 24.93 117.327 25.8625 118.056 26.5798C118.786 27.2971 119.661 27.6558 120.682 27.6558C122.359 27.5841 123.599 26.9385 124.329 25.6473Z"
          fill={theme.text16}
        />
        <path
          d="M132.132 20.3409C132.789 19.2649 133.664 18.4759 134.831 17.8303C135.998 17.2565 137.311 16.8978 138.769 16.8978C140.228 16.8978 141.541 17.1847 142.708 17.8303C143.802 18.4042 144.677 19.2649 145.333 20.3409C145.917 21.4168 146.281 22.5645 146.281 23.9274C146.281 25.2902 145.99 26.4379 145.333 27.5139C144.75 28.5898 143.875 29.4506 142.708 30.0244C141.614 30.5983 140.228 30.9569 138.769 30.9569C137.311 30.9569 135.998 30.67 134.831 30.0961C133.664 29.5223 132.789 28.7333 132.132 27.6573C131.476 26.5814 131.184 25.362 131.184 23.8556C131.184 22.6362 131.476 21.4168 132.132 20.3409ZM136.363 26.5814C136.946 27.2987 137.748 27.5856 138.696 27.5856C139.645 27.5856 140.447 27.2269 141.03 26.5814C141.614 25.9358 141.905 25.0033 141.905 23.9991C141.905 22.9231 141.614 22.0624 141.03 21.4168C140.447 20.7712 139.645 20.4126 138.696 20.4126C137.748 20.4126 137.019 20.7712 136.363 21.4168C135.706 22.0624 135.414 22.9949 135.414 23.9991C135.487 25.0033 135.779 25.8641 136.363 26.5814Z"
          fill={theme.text16}
        />
        <path
          d="M161.233 18.044C162.108 18.7613 162.473 19.6938 162.546 20.8414V30.6684H158.242V22.4195C158.17 21.1284 157.513 20.4828 156.273 20.4828C155.325 20.4828 154.523 20.9132 153.939 21.8457C153.356 22.7781 153.064 23.9258 153.064 25.2887V30.6684H148.834V17.6136H152.7L152.991 19.6938C153.502 18.833 154.158 18.1874 155.033 17.6853C155.909 17.1832 156.93 16.968 158.097 16.968C159.336 16.968 160.43 17.3267 161.233 18.044Z"
          fill={theme.text16}
        />
        <path
          d="M37.8297 17.365C37.1003 16.9346 36.1522 16.9346 35.35 17.365L29.6612 20.6646L25.7957 22.8165L20.1069 26.116C19.3776 26.5464 18.4294 26.5464 17.6272 26.116L13.1053 23.5338C12.376 23.1034 11.8655 22.3143 11.8655 21.4536V16.3608C11.8655 15.5 12.3031 14.711 13.1053 14.2806L17.5542 11.77C18.2836 11.3397 19.2317 11.3397 20.034 11.77L24.4829 14.2806C25.2122 14.711 25.7228 15.5 25.7228 16.3608V19.6603L29.5882 17.4367V14.1371C29.5882 13.2764 29.1506 12.4873 28.3484 12.057L20.1069 7.32278C19.3776 6.89241 18.4294 6.89241 17.6272 7.32278L9.23986 12.057C8.4376 12.4873 8 13.2764 8 14.1371V23.6772C8 24.538 8.4376 25.327 9.23986 25.7574L17.6272 30.4916C18.3565 30.9219 19.3046 30.9219 20.1069 30.4916L25.7957 27.2637L29.6612 25.0401L35.35 21.8122C36.0793 21.3819 37.0274 21.3819 37.8297 21.8122L42.2786 24.3228C43.0079 24.7532 43.5185 25.5422 43.5185 26.403V31.4958C43.5185 32.3565 43.0809 33.1456 42.2786 33.576L37.8297 36.1582C37.1003 36.5886 36.1522 36.5886 35.35 36.1582L30.901 33.6477C30.1717 33.2173 29.6612 32.4283 29.6612 31.5675V28.2679L25.7957 30.4916V33.7911C25.7957 34.6519 26.2333 35.4409 27.0356 35.8713L35.4229 40.6055C36.1522 41.0359 37.1003 41.0359 37.9026 40.6055L46.2899 35.8713C47.0193 35.4409 47.5298 34.6519 47.5298 33.7911V24.2511C47.5298 23.3903 47.0922 22.6013 46.2899 22.1709L37.8297 17.365Z"
          fill="#8247E5"
        />
      </g>
    </svg>
  )
}

export default Polygon
