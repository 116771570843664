function GasStation({ size, color }: { size?: number; color?: string }) {
  return (
    <svg width={size || 16} height={size || 16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M13.18 4.82L13.1867 4.81333L11.06 2.68667C10.8667 2.49333 10.5467 2.49333 10.3534 2.68667C10.16 2.88 10.16 3.2 10.3534 3.39333L11.4067 4.44667C10.7067 4.71333 10.2334 5.42667 10.3534 6.25333C10.46 6.98667 11.0867 7.58 11.82 7.66C12.1334 7.69333 12.4067 7.64 12.6667 7.52667V12.3333C12.6667 12.7 12.3667 13 12 13C11.6334 13 11.3334 12.7 11.3334 12.3333V9.33333C11.3334 8.6 10.7334 8 10 8H9.33335V3.33333C9.33335 2.6 8.73335 2 8.00002 2H4.00002C3.26669 2 2.66669 2.6 2.66669 3.33333V13.3333C2.66669 13.7 2.96669 14 3.33335 14H8.66669C9.03335 14 9.33335 13.7 9.33335 13.3333V9H10.3334V12.24C10.3334 13.1133 10.96 13.9067 11.8267 13.9933C12.8267 14.0933 13.6667 13.3133 13.6667 12.3333V6C13.6667 5.54 13.48 5.12 13.18 4.82ZM8.00002 6.66667H4.00002V4C4.00002 3.63333 4.30002 3.33333 4.66669 3.33333H7.33335C7.70002 3.33333 8.00002 3.63333 8.00002 4V6.66667ZM12 6.66667C11.6334 6.66667 11.3334 6.36667 11.3334 6C11.3334 5.63333 11.6334 5.33333 12 5.33333C12.3667 5.33333 12.6667 5.63333 12.6667 6C12.6667 6.36667 12.3667 6.66667 12 6.66667Z"
          fill={color || 'currentColor'}
        />
      </svg>
    </svg>
  )
}

export default GasStation
