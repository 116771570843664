import useTheme from 'hooks/useTheme'

function OasisLogoFull() {
  const theme = useTheme()

  return (
    <svg viewBox="0 0 88.2 24" width="100%" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g>
        <path
          d="M 29.81 11.43 C 29.81 15.246 32.636 18.198 36.578 18.198 C 40.52 18.198 43.364 15.246 43.364 11.43 C 43.364 7.614 40.52 4.662 36.578 4.662 C 32.636 4.662 29.81 7.614 29.81 11.43 Z M 32.384 11.43 C 32.384 9.036 34.13 7.2 36.578 7.2 C 39.044 7.2 40.772 9.036 40.772 11.43 C 40.772 13.824 39.044 15.66 36.578 15.66 C 34.13 15.66 32.384 13.824 32.384 11.43 Z M 53.974 18 L 56.584 18 L 52.3 4.86 L 49.654 4.86 L 45.334 18 L 47.89 18 L 48.502 16.128 L 53.362 16.128 L 53.974 18 Z M 49.312 13.644 L 50.932 8.658 L 52.552 13.644 L 49.312 13.644 Z M 65.969 8.694 L 68.543 8.694 C 68.543 6.3 66.545 4.662 63.845 4.662 C 61.091 4.662 59.183 6.408 59.183 8.802 C 59.183 10.476 60.119 11.79 61.847 12.276 C 63.017 12.6 64.403 12.51 65.249 12.816 C 65.771 12.996 66.185 13.41 66.185 14.058 C 66.185 15.012 65.375 15.75 63.989 15.75 C 62.639 15.75 61.631 15.048 61.631 14.004 L 59.075 14.004 C 59.075 16.488 61.163 18.198 63.953 18.198 C 66.833 18.198 68.741 16.398 68.741 13.914 C 68.741 12.15 67.715 10.872 66.041 10.368 C 64.781 9.99 63.503 10.152 62.657 9.846 C 62.099 9.648 61.739 9.234 61.739 8.676 C 61.739 7.794 62.531 7.11 63.809 7.11 C 65.069 7.11 65.933 7.74 65.969 8.694 Z M 72.323 18 L 74.897 18 L 74.897 4.86 L 72.323 4.86 L 72.323 18 Z M 85.425 8.694 L 87.999 8.694 C 87.999 6.3 86.001 4.662 83.301 4.662 C 80.547 4.662 78.639 6.408 78.639 8.802 C 78.639 10.476 79.575 11.79 81.303 12.276 C 82.473 12.6 83.859 12.51 84.705 12.816 C 85.227 12.996 85.641 13.41 85.641 14.058 C 85.641 15.012 84.831 15.75 83.445 15.75 C 82.095 15.75 81.087 15.048 81.087 14.004 L 78.531 14.004 C 78.531 16.488 80.619 18.198 83.409 18.198 C 86.289 18.198 88.197 16.398 88.197 13.914 C 88.197 12.15 87.171 10.872 85.497 10.368 C 84.237 9.99 82.959 10.152 82.113 9.846 C 81.555 9.648 81.195 9.234 81.195 8.676 C 81.195 7.794 81.987 7.11 83.265 7.11 C 84.525 7.11 85.389 7.74 85.425 8.694 Z"
          fill={theme.text}
        ></path>

        {/* <path
          d="M 36.81 11.43 C 36.81 15.246 39.636 18.198 43.578 18.198 C 47.52 18.198 50.364 15.246 50.364 11.43 C 50.364 7.614 47.52 4.662 43.578 4.662 C 39.636 4.662 36.81 7.614 36.81 11.43 Z M 39.384 11.43 C 39.384 9.036 41.13 7.2 43.578 7.2 C 46.044 7.2 47.772 9.036 47.772 11.43 C 47.772 13.824 46.044 15.66 43.578 15.66 C 41.13 15.66 39.384 13.824 39.384 11.43 Z M 60.974 18 L 63.584 18 L 59.3 4.86 L 56.654 4.86 L 52.334 18 L 54.89 18 L 55.502 16.128 L 60.362 16.128 L 60.974 18 Z M 56.312 13.644 L 57.932 8.658 L 59.552 13.644 L 56.312 13.644 Z M 72.969 8.694 L 75.543 8.694 C 75.543 6.3 73.545 4.662 70.845 4.662 C 68.091 4.662 66.183 6.408 66.183 8.802 C 66.183 10.476 67.119 11.79 68.847 12.276 C 70.017 12.6 71.403 12.51 72.249 12.816 C 72.771 12.996 73.185 13.41 73.185 14.058 C 73.185 15.012 72.375 15.75 70.989 15.75 C 69.639 15.75 68.631 15.048 68.631 14.004 L 66.075 14.004 C 66.075 16.488 68.163 18.198 70.953 18.198 C 73.833 18.198 75.741 16.398 75.741 13.914 C 75.741 12.15 74.715 10.872 73.041 10.368 C 71.781 9.99 70.503 10.152 69.657 9.846 C 69.099 9.648 68.739 9.234 68.739 8.676 C 68.739 7.794 69.531 7.11 70.809 7.11 C 72.069 7.11 72.933 7.74 72.969 8.694 Z M 79.323 18 L 81.897 18 L 81.897 4.86 L 79.323 4.86 L 79.323 18 Z M 92.425 8.694 L 94.999 8.694 C 94.999 6.3 93.001 4.662 90.301 4.662 C 87.547 4.662 85.639 6.408 85.639 8.802 C 85.639 10.476 86.575 11.79 88.303 12.276 C 89.473 12.6 90.859 12.51 91.705 12.816 C 92.227 12.996 92.641 13.41 92.641 14.058 C 92.641 15.012 91.831 15.75 90.445 15.75 C 89.095 15.75 88.087 15.048 88.087 14.004 L 85.531 14.004 C 85.531 16.488 87.619 18.198 90.409 18.198 C 93.289 18.198 95.197 16.398 95.197 13.914 C 95.197 12.15 94.171 10.872 92.497 10.368 C 91.237 9.99 89.959 10.152 89.113 9.846 C 88.555 9.648 88.195 9.234 88.195 8.676 C 88.195 7.794 88.987 7.11 90.265 7.11 C 91.525 7.11 92.389 7.74 92.425 8.694 Z"
          fill={theme.text}
        /> */}
        <path
          d="M21.565 4.75358C19.7483 2.42922 17.0816 0.958206 14.1326 0.958206C8.42918 0.958206 4.25647 6.25826 4.25647 12C4.25647 16.0027 6.91283 18.9981 9.86786 18.9981C10.1439 18.9981 10.4179 18.9731 10.6879 18.9234C8.70885 17.48 7.4425 14.851 7.4425 12C7.4425 7.46027 10.5135 3.92257 14.1326 3.92257C18.0796 3.92257 20.8243 7.84227 20.8243 12C20.814 15.0707 19.7193 18.1237 17.7929 20.3764C16.1116 22.3428 13.9339 23.5898 11.5395 23.9854C11.5395 23.9854 11.7392 24.0001 12.0002 24.0001C18.6276 24.0001 24 18.6274 24 12C24 9.27729 23.0927 6.76693 21.565 4.75358ZM19.7435 12.0001C19.7435 7.99708 17.0872 5.00172 14.1325 5.00172C13.8561 5.00172 13.5825 5.02672 13.3121 5.07672C15.2915 6.52006 16.5575 9.14909 16.5575 12.0001C16.5575 16.5398 13.4868 20.0775 9.86776 20.0775C5.92039 20.0775 3.17603 16.1578 3.17603 12.0001C3.18603 8.92942 4.28104 5.87639 6.20706 3.6237C7.88874 1.65735 10.0664 0.410337 12.4605 0.0146668C12.4605 0.0146668 12.2611 0 12.0001 0C5.37272 0 0 5.37272 0 12.0001C0 14.7225 0.907342 17.2328 2.43536 19.2469C4.25171 21.5705 6.91873 23.0419 9.86776 23.0419C15.5712 23.0419 19.7435 17.7418 19.7435 12.0001Z"
          fill="#0092f6"
        />
      </g>
    </svg>
  )
}

export default OasisLogoFull
