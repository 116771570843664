export const Z_INDEXS = {
  ICON_SUPPORT: 99,
  LIVE_CHART: 99999,
  MODAL: 999999,

  POPUP_NOTIFICATION: 9999,
  POPOVER_CONTAINER: 9999,

  SUGGESTION_PAIR: 2,
  TOOL_TIP_ERROR_INPUT_SWAP_FORM: 1,
}
