import useTheme from 'hooks/useTheme'

function CronosLogoFull({ color }: { size?: number; color?: string }) {
  const theme = useTheme()

  return (
    <svg width="215" height="60" viewBox="0 0 215 60" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0)">
        <path
          d="M25.84 1.90735e-06L-1.52588e-05 14.92V44.75L25.84 59.66L51.66 44.75V14.92L25.84 1.90735e-06ZM44.01 40.33L25.84 50.82L7.65999 40.33V19.33L25.84 8.84L44.01 19.33V40.33Z"
          fill={color || theme.text}
        />
        <path
          d="M37.89 36.8L25.83 43.76L13.76 36.8V22.87L25.83 15.9L37.89 22.87L32.87 25.77L25.83 21.7L18.79 25.77V33.89L25.83 37.96L32.87 33.89L37.89 36.8Z"
          fill={color || theme.text}
        />
        <path
          d="M70.78 32.0903V32.0103C70.7626 30.4296 71.0611 28.8614 71.658 27.3976C72.2549 25.9338 73.1381 24.604 74.2559 23.4862C75.3737 22.3684 76.7035 21.4852 78.1673 20.8883C79.631 20.2914 81.1993 19.9929 82.78 20.0103C84.5131 19.9497 86.2379 20.2774 87.828 20.9695C89.4181 21.6616 90.8333 22.7006 91.97 24.0103L88.66 27.5403C87.05 25.8803 85.35 24.6603 82.74 24.6603C78.91 24.6603 76.08 27.9703 76.08 31.9803V32.0703C76.08 36.1603 78.91 39.4203 82.95 39.4203C84.0931 39.3982 85.2186 39.1346 86.2526 38.6469C87.2866 38.1591 88.2059 37.4583 88.95 36.5903L92.13 39.7303C90.5505 41.6177 88.4309 42.9762 86.0562 43.6231C83.6816 44.27 81.1659 44.1743 78.8473 43.3488C76.5287 42.5233 74.5185 41.0076 73.087 39.0056C71.6555 37.0036 70.8713 34.6113 70.84 32.1503L70.78 32.0903Z"
          fill={color || theme.text}
        />
        <path
          d="M96.86 20.4703H102.17V25.6503C103.61 22.2103 106.26 19.8603 110.31 20.0303V25.6103H110.01C105.39 25.6103 102.17 28.6103 102.17 34.7003V43.4503H96.86V20.4703Z"
          fill={color || theme.text}
        />
        <path
          d="M112.84 32.0903V32.0103C112.919 28.8199 114.238 25.7858 116.519 23.5529C118.799 21.3201 121.86 20.0643 125.051 20.0526C128.243 20.0409 131.313 21.2741 133.609 23.4902C135.906 25.7063 137.248 28.7305 137.35 31.9203V32.0103C137.351 33.6135 137.03 35.2005 136.407 36.6777C135.784 38.1548 134.871 39.4922 133.723 40.6108C132.574 41.7294 131.214 42.6065 129.721 43.1904C128.227 43.7743 126.633 44.0531 125.03 44.0103C123.441 44.0504 121.86 43.7715 120.381 43.1901C118.901 42.6087 117.554 41.7365 116.417 40.6252C115.281 39.5139 114.379 38.186 113.764 36.7201C113.15 35.2541 112.835 33.6798 112.84 32.0903ZM132.08 32.0903V32.0103C132.12 31.0567 131.968 30.1048 131.633 29.2112C131.297 28.3176 130.785 27.5008 130.127 26.8093C129.47 26.1178 128.679 25.5658 127.803 25.1863C126.928 24.8068 125.984 24.6075 125.03 24.6003C120.8 24.6003 118.1 27.9103 118.1 31.9203V32.0103C118.064 32.9559 118.218 33.899 118.552 34.7841C118.887 35.6692 119.395 36.4783 120.048 37.1635C120.7 37.8487 121.484 38.3961 122.352 38.7734C123.219 39.1508 124.154 39.3503 125.1 39.3603C129.38 39.3603 132.08 36.0503 132.08 32.0903Z"
          fill={color || theme.text}
        />
        <path
          d="M142.66 20.4703H147.95V24.0003C148.7 22.7682 149.756 21.7518 151.017 21.0507C152.277 20.3495 153.698 19.9876 155.14 20.0003C160.32 20.0003 163.32 23.4803 163.32 28.7903V43.4603H158.05V30.3903C158.05 26.8203 156.27 24.7803 153.13 24.7803C149.99 24.7803 147.95 26.9203 147.95 30.4803V43.4803H142.64L142.66 20.4703Z"
          fill={color || theme.text}
        />
        <path
          d="M168.44 32.0903V32.0103C168.519 28.8199 169.838 25.7858 172.119 23.5529C174.399 21.3201 177.46 20.0643 180.651 20.0526C183.843 20.0409 186.913 21.2741 189.209 23.4902C191.506 25.7063 192.848 28.7305 192.95 31.9203V32.0103C192.951 33.6135 192.63 35.2005 192.007 36.6777C191.384 38.1548 190.471 39.4922 189.323 40.6108C188.175 41.7294 186.814 42.6065 185.321 43.1904C183.827 43.7743 182.233 44.0531 180.63 44.0103C179.041 44.0504 177.46 43.7715 175.981 43.1901C174.501 42.6087 173.154 41.7365 172.017 40.6252C170.881 39.5139 169.979 38.186 169.364 36.7201C168.75 35.2541 168.435 33.6798 168.44 32.0903ZM187.68 32.0903V32.0103C187.72 31.0567 187.568 30.1048 187.233 29.2112C186.897 28.3176 186.385 27.5008 185.727 26.8093C185.07 26.1178 184.279 25.5658 183.403 25.1863C182.528 24.8068 181.584 24.6075 180.63 24.6003C176.4 24.6003 173.7 27.9103 173.7 31.9203V32.0103C173.664 32.9559 173.818 33.899 174.152 34.7841C174.487 35.6692 174.995 36.4783 175.648 37.1635C176.301 37.8487 177.084 38.3961 177.952 38.7734C178.819 39.1508 179.754 39.3503 180.7 39.3603C184.95 39.3603 187.68 36.0503 187.68 32.0903Z"
          fill={color || theme.text}
        />
        <path
          d="M196.43 40.4503L198.78 36.8803C200.928 38.596 203.564 39.5868 206.31 39.7103C208.58 39.7103 209.88 38.7103 209.88 37.2303V37.1403C209.88 35.3603 207.45 34.7503 204.75 33.9203C201.35 32.9203 197.56 31.5703 197.56 27.2203V27.1303C197.56 22.7303 201.18 20.0803 205.75 20.0803C208.753 20.1361 211.679 21.041 214.19 22.6903L212.1 26.4303C210.166 25.1554 207.932 24.4074 205.62 24.2603C203.62 24.2603 202.4 25.2603 202.4 26.5203V26.6103C202.4 28.3103 204.88 29.0003 207.58 29.8703C210.93 30.9203 214.72 32.4403 214.72 36.5803V36.6603C214.72 41.5003 210.97 43.8903 206.18 43.8903C202.637 43.8649 199.205 42.6539 196.43 40.4503Z"
          fill={color || theme.text}
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="214.71" height="59.66" fill={color || theme.text} />
        </clipPath>
      </defs>
    </svg>
  )
}

export default CronosLogoFull
