const DarkModeIconActive = ({ width, height }: { width?: number; height?: number }) => {
  return (
    <svg width={width || 10} height={height || 12} viewBox="0 0 10 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.911 0.924993C5.105 0.914993 5.247 1.10899 5.166 1.28199C5.086 1.44899 5.016 1.61699 4.956 1.78699C4.936 1.84199 4.92 1.89199 4.904 1.94199L4.903 1.94599C4.023 4.71799 5.702 7.66299 8.484 8.37599L8.487 8.37699C8.531 8.38899 8.575 8.39899 8.62 8.40999L8.651 8.41699C8.826 8.45499 9.005 8.48499 9.188 8.50599C9.376 8.52599 9.462 8.74999 9.356 8.90799C9.278 9.01999 9.196 9.12799 9.109 9.23299C9.038 9.31899 8.968 9.39899 8.896 9.47599L8.88 9.49299C7.775 10.665 6.123 11.31 4.33 11.02C2.27 10.69 0.584996 9.06599 0.187996 7.01399C-0.370004 4.15299 1.498 1.54799 4.167 1.01699L4.185 1.01399C4.285 0.993993 4.387 0.976993 4.49 0.963993L4.497 0.962993C4.633 0.944993 4.771 0.932993 4.911 0.924993ZM3.787 2.17699C2.035 2.80699 0.863996 4.60499 1.15 6.60499L1.186 6.82099C1.502 8.45099 2.85 9.75099 4.492 10.015H4.494C5.711 10.213 6.842 9.87499 7.697 9.19999C4.852 8.18399 3.132 5.16199 3.787 2.17699Z"
        fill="white"
      />
    </svg>
  )
}

export default DarkModeIconActive
