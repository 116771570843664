import React, { CSSProperties } from 'react'

const Kyber = ({ size = 24, style, color = '#31CB9E' }: { size?: number; style?: CSSProperties; color?: string }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" style={style}>
      <path
        d="M10.9646 11.996L18.393 16.2792C18.4524 16.313 18.5195 16.3306 18.5878 16.3304C18.656 16.3302 18.7231 16.3122 18.7823 16.2781C18.8415 16.244 18.8909 16.195 18.9256 16.1359C18.9603 16.0768 18.9792 16.0097 18.9803 15.9411L18.9816 8.05348C18.9808 7.98473 18.9622 7.91738 18.9276 7.85809C18.893 7.79879 18.8435 7.74962 18.7842 7.71544C18.7249 7.68126 18.6577 7.66325 18.5893 7.66321C18.5209 7.66316 18.4537 7.68108 18.3944 7.71518L10.9646 11.996Z"
        fill={color}
      />
      <path
        d="M17.9614 5.86933L13.0302 2.07949C12.9776 2.03976 12.9159 2.01389 12.8508 2.00424C12.7857 1.99459 12.7192 2.00147 12.6575 2.02425C12.5957 2.04703 12.5405 2.08499 12.4971 2.13467C12.4536 2.18435 12.4232 2.24417 12.4087 2.30868L10.6056 10.7463L17.9213 6.52281C17.9779 6.49102 18.0257 6.44544 18.0602 6.39027C18.0947 6.3351 18.1148 6.2721 18.1188 6.20706C18.1228 6.14201 18.1105 6.077 18.0829 6.01799C18.0554 5.95898 18.0136 5.90787 17.9614 5.86933Z"
        fill={color}
      />
      <path
        d="M13.0269 21.9187L17.9707 18.1247C18.0218 18.0854 18.0625 18.0341 18.0893 17.9753C18.116 17.9164 18.1279 17.8519 18.124 17.7873C18.12 17.7228 18.1004 17.6602 18.0667 17.605C18.033 17.5499 17.9864 17.504 17.9309 17.4712L10.6051 13.2511L12.4111 21.6893C12.4244 21.754 12.4538 21.8142 12.4965 21.8644C12.5393 21.9145 12.5941 21.9528 12.6557 21.9758C12.7173 21.9987 12.7837 22.0055 12.8486 21.9955C12.9136 21.9855 12.9749 21.9591 13.0269 21.9187Z"
        fill={color}
      />
      <path
        d="M9.06026 11.9957L11.0059 2.90467C11.0228 2.82593 11.0152 2.74386 10.984 2.66967C10.9527 2.59548 10.8995 2.53278 10.8316 2.49013C10.7636 2.44748 10.6842 2.42696 10.6042 2.43137C10.5242 2.43578 10.4475 2.46491 10.3845 2.51478L5.40651 6.33738C5.28039 6.43435 5.17823 6.55928 5.10799 6.70244C5.03776 6.8456 5.00134 7.00313 5.00157 7.16276L5 16.8446C4.99972 17.0042 5.03609 17.1617 5.10628 17.3049C5.17647 17.4481 5.27858 17.5731 5.40467 17.6701L10.3815 21.4886C10.4449 21.5364 10.5211 21.5638 10.6003 21.5672C10.6794 21.5706 10.7578 21.5499 10.825 21.5078C10.8922 21.4656 10.9452 21.404 10.977 21.331C11.0087 21.2581 11.0178 21.1772 11.003 21.0989L9.06026 11.9957Z"
        fill={color}
      />
    </svg>
  )
}

export default Kyber
