export default function Reddit({ size, color }: { size?: number | string; color?: string }) {
  return (
    <svg width={size || '17'} height={size || '36'} viewBox="0 0 17 17">
      <g></g>
      <path
        d="M15.581 9.936c0.024 0.161 0.040 0.33 0.040 0.491 0 1.308-0.766 2.517-2.145 3.42-1.347 0.879-3.121 1.363-5.008 1.363s-3.669-0.484-5.008-1.363c-1.388-0.903-2.145-2.112-2.145-3.42 0-0.177 0.016-0.354 0.040-0.532-0.508-0.322-0.855-0.895-0.855-1.541 0-1.007 0.815-1.822 1.822-1.822 0.452 0 0.872 0.17 1.194 0.444 1.291-0.823 2.976-1.291 4.774-1.324l1.081-3.41c0.048-0.153 0.21-0.242 0.371-0.21l2.799 0.661c0.233-0.532 0.766-0.903 1.379-0.903 0.831 0 1.5 0.678 1.5 1.501 0 0.83-0.669 1.508-1.5 1.508-0.823 0-1.492-0.67-1.5-1.492l-2.541-0.597-0.935 2.951c1.701 0.072 3.29 0.541 4.516 1.339 0.322-0.29 0.75-0.468 1.218-0.468 1.007 0 1.822 0.815 1.822 1.822 0 0.678-0.371 1.267-0.919 1.582zM1.532 9.25c0.258-0.693 0.75-1.339 1.451-1.896-0.184-0.128-0.419-0.201-0.661-0.201-0.661 0-1.201 0.54-1.201 1.201 0 0.356 0.161 0.678 0.411 0.896zM15 10.427c0-1.080-0.662-2.112-1.863-2.896-1.242-0.806-2.903-1.257-4.669-1.257s-3.428 0.452-4.67 1.257c-1.202 0.783-1.863 1.815-1.863 2.896 0 1.089 0.661 2.121 1.863 2.904 1.242 0.806 2.903 1.258 4.669 1.258s3.428-0.452 4.669-1.258c1.202-0.783 1.864-1.815 1.864-2.904zM6.097 10.661c-0.605 0-1.121-0.492-1.121-1.097 0-0.612 0.516-1.121 1.121-1.121s1.105 0.509 1.105 1.121c0 0.605-0.5 1.097-1.105 1.097zM11.081 12.267c0.121 0.12 0.121 0.322 0 0.443-0.54 0.54-1.379 0.798-2.573 0.798h-0.016c-1.194 0-2.033-0.258-2.573-0.798-0.121-0.121-0.121-0.323 0-0.443 0.121-0.122 0.314-0.122 0.436 0 0.419 0.419 1.113 0.62 2.137 0.62h0.016c1.017 0 1.718-0.201 2.137-0.62 0.121-0.122 0.314-0.122 0.436 0zM12.024 9.564c0 0.604-0.5 1.097-1.105 1.097s-1.121-0.492-1.121-1.097c0-0.612 0.516-1.121 1.121-1.121s1.105 0.509 1.105 1.121zM13.040 3.291c0 0.482 0.395 0.878 0.879 0.878s0.879-0.396 0.879-0.878c0-0.484-0.396-0.88-0.879-0.88-0.484 0-0.879 0.396-0.879 0.88zM15.879 8.354c0-0.661-0.54-1.201-1.201-1.201-0.258 0-0.5 0.081-0.694 0.226 0.694 0.557 1.185 1.21 1.436 1.92 0.282-0.227 0.459-0.566 0.459-0.945z"
        fill={color || '#000000'}
      />
    </svg>
  )
}
