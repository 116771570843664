function BestPrice({ size }: { size?: number }) {
  return (
    <svg width={size || 24} height={size || 24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.25122 11.6355V7.13037H14.2615V11.6355"
        stroke="#27A27E"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.2615 7.13016H15.3878V2.625H6.37744V7.13016"
        stroke="#27A27E"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.25 16.125V20.625H15.375"
        stroke="#27A27E"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.375 11.625H4.125V16.125H10.875"
        stroke="#27A27E"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.557 12.943C20.3143 14.7004 20.3143 17.5496 18.557 19.307C16.7996 21.0643 13.9504 21.0643 12.193 19.307C10.4357 17.5496 10.4357 14.7004 12.193 12.943C13.9504 11.1857 16.7996 11.1857 18.557 12.943"
        stroke="#31CB9E"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.3642 15.7643C15.0362 15.7643 14.7693 15.5333 14.7693 15.2494C14.7693 14.9655 15.0362 14.7345 15.3642 14.7345C15.6922 14.7345 15.9591 14.9655 15.9591 15.2494C15.9591 15.3987 16.0802 15.5198 16.2295 15.5198C16.3788 15.5198 16.4999 15.3987 16.4999 15.2494C16.4999 14.7539 16.1307 14.3372 15.6346 14.2242V14.0835C15.6346 13.9342 15.5135 13.8131 15.3642 13.8131C15.2149 13.8131 15.0938 13.9342 15.0938 14.0835V14.2242C14.5977 14.3372 14.2285 14.7539 14.2285 15.2494C14.2285 15.8315 14.738 16.3051 15.3642 16.3051C15.6922 16.3051 15.9591 16.5361 15.9591 16.82C15.9591 17.1039 15.6922 17.3349 15.3642 17.3349C15.0362 17.3349 14.7693 17.1039 14.7693 16.82C14.7693 16.6707 14.6482 16.5496 14.4989 16.5496C14.3496 16.5496 14.2285 16.6707 14.2285 16.82C14.2285 17.3155 14.5977 17.7321 15.0938 17.8452V17.962C15.0938 18.1114 15.2149 18.2324 15.3642 18.2324C15.5135 18.2324 15.6346 18.1114 15.6346 17.962V17.8452C16.1307 17.7321 16.4999 17.3155 16.4999 16.82C16.4999 16.2379 15.9904 15.7643 15.3642 15.7643Z"
        fill="#31CB9E"
      />
    </svg>
  )
}

export default BestPrice
