import React, { CSSProperties } from 'react'

function Cart({ size = 20, color, style }: { size?: number; color?: string; style?: CSSProperties }) {
  return (
    <svg width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" style={style}>
      <path
        d="M5.83325 14.9998C4.91658 14.9998 4.17492 15.7498 4.17492 16.6665C4.17492 17.5832 4.91658 18.3332 5.83325 18.3332C6.74992 18.3332 7.49992 17.5832 7.49992 16.6665C7.49992 15.7498 6.74992 14.9998 5.83325 14.9998ZM0.833252 2.49984C0.833252 2.95817 1.20825 3.33317 1.66659 3.33317H2.49992L5.49992 9.65817L4.37492 11.6915C3.76659 12.8082 4.56659 14.1665 5.83325 14.1665H14.9999C15.4583 14.1665 15.8333 13.7915 15.8333 13.3332C15.8333 12.8748 15.4583 12.4998 14.9999 12.4998H5.83325L6.74992 10.8332H12.9583C13.5833 10.8332 14.1333 10.4915 14.4166 9.97484L17.3999 4.5665C17.7083 4.0165 17.3083 3.33317 16.6749 3.33317H4.34159L3.78325 2.1415C3.64992 1.84984 3.34992 1.6665 3.03325 1.6665H1.66659C1.20825 1.6665 0.833252 2.0415 0.833252 2.49984ZM14.1666 14.9998C13.2499 14.9998 12.5083 15.7498 12.5083 16.6665C12.5083 17.5832 13.2499 18.3332 14.1666 18.3332C15.0833 18.3332 15.8333 17.5832 15.8333 16.6665C15.8333 15.7498 15.0833 14.9998 14.1666 14.9998Z"
        fill={color || 'currentColor'}
      />
    </svg>
  )
}

export default Cart
