function SolanaLogoFull() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="140" height="22" viewBox="0 0 140 22" fill="none">
      <g clipPath="url(#clip0_37_3024)">
        <path
          d="M23.5204 17.0607L19.6802 21.1784C19.5971 21.2678 19.4965 21.3392 19.3847 21.388C19.2728 21.4369 19.1521 21.4622 19.03 21.4623H0.825704C0.738881 21.4622 0.653953 21.4369 0.581322 21.3893C0.508693 21.3417 0.451514 21.274 0.416785 21.1944C0.382056 21.1148 0.371285 21.0269 0.385792 20.9413C0.4003 20.8556 0.439452 20.7761 0.49846 20.7124L4.33437 16.5948C4.41744 16.5053 4.51802 16.434 4.62989 16.3851C4.74174 16.3363 4.86247 16.311 4.98453 16.3109H23.1889C23.2764 16.3091 23.3627 16.3332 23.4366 16.3803C23.5105 16.4274 23.569 16.4952 23.6043 16.5754C23.6399 16.6555 23.6507 16.7444 23.6357 16.8308C23.6208 16.9171 23.5807 16.9971 23.5204 17.0607ZM19.6802 8.76691C19.5968 8.67788 19.4962 8.6068 19.3844 8.55797C19.2726 8.50916 19.152 8.48366 19.03 8.48303H0.825704C0.738881 8.48307 0.653953 8.50843 0.581322 8.55602C0.508693 8.60359 0.451514 8.67131 0.416785 8.75089C0.382056 8.83047 0.371285 8.91844 0.385792 9.00404C0.4003 9.08964 0.439452 9.16918 0.49846 9.23287L4.33437 13.3527C4.41774 13.4417 4.51841 13.5128 4.63019 13.5616C4.74198 13.6104 4.86256 13.6359 4.98453 13.6366H23.1889C23.2755 13.6361 23.3601 13.6104 23.4325 13.5627C23.5048 13.515 23.5616 13.4472 23.5961 13.3677C23.6305 13.2882 23.6412 13.2004 23.6264 13.115C23.6119 13.0296 23.5729 12.9503 23.5139 12.8867L19.6802 8.76691ZM0.825704 5.8087H19.03C19.1521 5.8086 19.2728 5.7833 19.3847 5.73446C19.4965 5.68563 19.5971 5.61424 19.6802 5.5248L23.5204 1.40717C23.5807 1.34356 23.6208 1.26355 23.6357 1.17719C23.6507 1.09083 23.6399 1.00198 23.6043 0.921826C23.569 0.841671 23.5105 0.77379 23.4366 0.726724C23.3627 0.679659 23.2764 0.655512 23.1889 0.657321H4.98453C4.86247 0.65745 4.74174 0.682725 4.62989 0.73157C4.51802 0.780415 4.41744 0.851783 4.33437 0.941222L0.49846 5.05886C0.439452 5.12255 0.4003 5.20209 0.385792 5.28769C0.371285 5.3733 0.382056 5.46126 0.416785 5.54084C0.451514 5.62042 0.508693 5.68814 0.581322 5.73571C0.653953 5.7833 0.738881 5.80866 0.825704 5.8087Z"
          fill="url(#paint0_linear_37_3024)"
        />
        <path
          d="M45.7146 9.45617H35.9753V6.24874H48.2458V3.04131H35.9428C35.5223 3.03903 35.1058 3.11956 34.7166 3.27831C34.3273 3.43708 33.973 3.67094 33.6744 3.96656C33.3755 4.26219 33.1378 4.61379 32.9748 5.00126C32.8118 5.38876 32.7267 5.80455 32.7245 6.2249V9.47567C32.7262 9.89639 32.8107 10.3127 32.9735 10.7006C33.1363 11.0886 33.3738 11.4407 33.6726 11.7368C33.9717 12.0329 34.326 12.2672 34.7155 12.4262C35.1049 12.5852 35.5221 12.6659 35.9428 12.6636H45.6951V15.871H32.9564V19.0785H45.7146C46.135 19.0808 46.5515 19.0002 46.9408 18.8415C47.33 18.6827 47.6841 18.4488 47.983 18.1532C48.2818 17.8576 48.5196 17.506 48.6825 17.1185C48.8455 16.731 48.9307 16.3152 48.9328 15.8949V12.6441C48.9311 12.2234 48.8466 11.8071 48.6838 11.4191C48.5211 11.0311 48.2836 10.679 47.9847 10.383C47.6856 10.0869 47.3313 9.85263 46.9419 9.6936C46.5524 9.53458 46.1352 9.45389 45.7146 9.45617Z"
          fill="white"
        />
        <path
          d="M64.5821 3.04112H54.8059C54.3848 3.0377 53.9672 3.11738 53.5769 3.27563C53.1868 3.43388 52.8316 3.66755 52.5319 3.9633C52.232 4.25903 51.9936 4.61101 51.8302 4.99906C51.6665 5.38712 51.5811 5.80363 51.579 6.22471V15.8947C51.5811 16.3158 51.6665 16.7323 51.8302 17.1203C51.9936 17.5084 52.232 17.8604 52.5319 18.1561C52.8316 18.4518 53.1868 18.6855 53.5769 18.8438C53.9672 19.002 54.3848 19.0817 54.8059 19.0783H64.5821C65.0025 19.0805 65.419 19 65.8083 18.8413C66.1975 18.6825 66.5518 18.4486 66.8505 18.153C67.1493 17.8574 67.3871 17.5058 67.55 17.1183C67.713 16.7308 67.7982 16.315 67.8003 15.8947V6.22471C67.7982 5.80437 67.713 5.38857 67.55 5.00108C67.3871 4.61361 67.1493 4.262 66.8505 3.96638C66.5518 3.67075 66.1975 3.43689 65.8083 3.27812C65.419 3.11938 65.0025 3.03885 64.5821 3.04112ZM64.5582 15.8708H54.8298V6.24855H64.5539L64.5582 15.8708Z"
          fill="white"
        />
        <path
          d="M98.8236 3.04125H89.288C88.8675 3.03898 88.451 3.11951 88.0618 3.27826C87.6726 3.43702 87.3182 3.67088 87.0196 3.96651C86.7207 4.26213 86.483 4.61374 86.32 5.00121C86.157 5.3887 86.0719 5.8045 86.0697 6.22484V19.0784H89.3205V13.81H98.8127V19.0784H102.064V6.22484C102.061 5.80265 101.976 5.38508 101.811 4.99616C101.647 4.60726 101.407 4.2547 101.106 3.95877C100.805 3.66287 100.448 3.42946 100.056 3.27199C99.6647 3.11453 99.2457 3.03612 98.8236 3.04125ZM98.7997 10.6026H89.3075V6.24868H98.7997V10.6026Z"
          fill="white"
        />
        <path
          d="M136.782 3.04131H127.246C126.826 3.03903 126.409 3.11956 126.02 3.27831C125.631 3.43708 125.277 3.67094 124.978 3.96656C124.679 4.26219 124.441 4.61379 124.278 5.00126C124.115 5.38876 124.03 5.80455 124.028 6.2249V19.0785H127.279V13.81H136.749V19.0785H140V6.2249C139.998 5.80455 139.913 5.38876 139.75 5.00126C139.587 4.61379 139.349 4.26219 139.05 3.96656C138.751 3.67094 138.397 3.43708 138.008 3.27831C137.619 3.11956 137.202 3.03903 136.782 3.04131ZM136.749 10.6026H127.257V6.24874H136.749V10.6026Z"
          fill="white"
        />
        <path
          d="M117.895 15.871H116.594L111.946 4.38494C111.787 3.98867 111.512 3.64905 111.159 3.40967C110.805 3.17026 110.388 3.04198 109.961 3.04129H107.076C106.796 3.03986 106.518 3.09367 106.258 3.19962C105.999 3.3056 105.763 3.46163 105.563 3.65885C105.364 3.85608 105.206 4.09059 105.097 4.34905C104.989 4.60749 104.932 4.8848 104.931 5.16513V19.0784H108.181V6.24872H109.482L114.128 17.7348C114.29 18.1303 114.566 18.4685 114.922 18.7063C115.277 18.9441 115.695 19.0706 116.122 19.0698H119.007C119.287 19.0712 119.565 19.0174 119.824 18.9114C120.084 18.8055 120.32 18.6494 120.519 18.4522C120.718 18.255 120.877 18.0205 120.985 17.762C121.094 17.5036 121.151 17.2263 121.152 16.9459V3.04129H117.895V15.871Z"
          fill="white"
        />
        <path
          d="M73.9226 3.04126H70.6719V15.8948C70.674 16.3161 70.7596 16.7328 70.9233 17.121C71.0869 17.5092 71.3255 17.8612 71.6254 18.157C71.9254 18.4528 72.281 18.6864 72.6713 18.8445C73.0618 19.0026 73.4797 19.0821 73.901 19.0784H83.6533V15.871H73.9226V3.04126Z"
          fill="white"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_37_3024"
          x1="2.34273"
          y1="21.9586"
          x2="21.4313"
          y2="0.438437"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.08" stopColor="#9945FF" />
          <stop offset="0.3" stopColor="#8752F3" />
          <stop offset="0.5" stopColor="#5497D5" />
          <stop offset="0.6" stopColor="#43B4CA" />
          <stop offset="0.72" stopColor="#28E0B9" />
          <stop offset="0.97" stopColor="#19FB9B" />
        </linearGradient>
        <clipPath id="clip0_37_3024">
          <rect width="140" height="20.805" fill="white" transform="translate(0 0.657227)" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default SolanaLogoFull
