function MoneyBag({ size, color }: { size?: number; color?: string }) {
  return (
    <svg width={size || 24} height={size || 24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.0001 7.12402C6.85117 7.12402 2.85864 12.7941 2.85864 17.6718C2.85864 23.3446 8.07625 24.0005 12.0001 24.0005C15.9239 24.0005 21.1415 23.3447 21.1415 17.6718C21.1415 12.7941 17.149 7.12402 12.0001 7.12402ZM12.7225 18.9511C12.7161 18.9534 12.7096 18.9523 12.7032 18.9545V19.7813C12.7032 20.17 12.3887 20.4845 12.0001 20.4845C11.6114 20.4845 11.2969 20.1699 11.2969 19.7813V18.9496C10.8333 18.7933 10.3893 18.4965 10.01 18.0446C9.7601 17.7473 9.79853 17.3037 10.0966 17.0537C10.3932 16.8038 10.8368 16.8415 11.0868 17.1395C12.4733 18.7955 13.4092 16.2654 12.0001 16.2654C10.8369 16.2654 9.89055 15.319 9.89055 14.1558C9.89055 13.3143 10.3723 12.512 11.2969 12.1829V11.343C11.2969 10.9543 11.6115 10.6398 12.0001 10.6398C12.3888 10.6398 12.7033 10.9544 12.7033 11.343V12.1852C13.0674 12.3078 13.4223 12.5027 13.7388 12.8091C14.0177 13.0796 14.0259 13.5247 13.7553 13.8035C13.4848 14.083 13.0398 14.0905 12.761 13.82C11.4674 12.5704 10.5937 14.859 12.0002 14.859C13.1634 14.859 14.1097 15.8053 14.1097 16.9685C14.1096 17.8517 13.552 18.649 12.7225 18.9511Z"
        fill={color || 'currentColor'}
      />
      <path
        d="M16.0104 0.566508C13.4146 -0.188836 10.5854 -0.188836 7.98967 0.566508C7.5541 0.692555 7.34617 1.19829 7.5804 1.59795L8.644 3.50201C10.4934 2.76612 13.2365 2.65624 15.3558 3.50234L16.4196 1.59795C16.6539 1.19829 16.4459 0.692602 16.0104 0.566508Z"
        fill={color || 'currentColor'}
      />
      <path
        d="M15.2648 5.0344C13.9491 4.10749 10.0896 4.06882 8.7268 5.03871C8.1425 5.45116 7.83144 6.09916 7.83749 6.77608C9.18777 6.06077 10.771 5.71694 11.9999 5.71694C13.4967 5.71694 14.8987 6.10666 16.1624 6.77608C16.1682 6.12391 15.8784 5.46705 15.2648 5.0344Z"
        fill={color || 'currentColor'}
      />
    </svg>
  )
}

export default MoneyBag
