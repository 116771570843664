import useTheme from 'hooks/useTheme'

function PoolElastic({ size, color }: { size?: number; color?: string }) {
  // style={{ transform: 'rotate(90deg)' }}
  const theme = useTheme()
  return (
    <svg width={size || 20} height={size || 20} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_13985_178979)">
        <path
          d="M16 8C16 11.4725 13.7883 14.4413 10.6666 15.5447C10.3417 15.6595 9.98425 15.4895 9.86902 15.1637C9.75403 14.8383 9.92468 14.4811 10.2501 14.3661C12.9417 13.4148 14.75 10.8564 14.75 8C14.75 4.8219 12.5725 2.22156 9.73181 1.47302L10.9003 2.64148C11.1443 2.8855 11.1443 3.28125 10.9003 3.52527C10.6561 3.76929 10.2605 3.76941 10.0164 3.52527L7.55798 1.06689C7.16492 0.673828 7.44482 0 8 0C12.422 0 16 3.57861 16 8ZM5.98364 12.4747C5.7395 12.2306 5.34387 12.2306 5.09973 12.4747C4.85571 12.7188 4.85571 13.1145 5.09973 13.3585L6.26819 14.527C3.42749 13.7784 1.25 11.1781 1.25 8C1.25 5.14355 3.05835 2.58521 5.75 1.63391C6.07544 1.51892 6.24597 1.16187 6.13098 0.836426C6.01599 0.510986 5.65894 0.340332 5.33337 0.455444C2.21167 1.55872 0 4.52747 0 8C0 12.4214 3.578 16 8 16C8.55518 16 8.83508 15.3262 8.44189 14.9331L5.98364 12.4747ZM8 4.91663C9.7002 4.91663 11.0834 6.2998 11.0834 8C11.0834 9.7002 9.7002 11.0834 8 11.0834C6.2998 11.0834 4.91663 9.7002 4.91663 8C4.91663 6.2998 6.2998 4.91663 8 4.91663ZM6.16663 8C6.16663 9.01086 6.98914 9.83337 8 9.83337C9.01086 9.83337 9.83337 9.01086 9.83337 8C9.83337 6.98914 9.01086 6.16663 8 6.16663C6.98914 6.16663 6.16663 6.98914 6.16663 8Z"
          fill={color || theme.primary}
        />
      </g>
      <defs>
        <clipPath id="clip0_13985_178979">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
export default PoolElastic
