import useTheme from 'hooks/useTheme'

function Oasis({ size }: { size?: number }) {
  const theme = useTheme()
  return (
    <svg width={size || 36} height={size || 36} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M21.565 4.75358C19.7483 2.42922 17.0816 0.958206 14.1326 0.958206C8.42918 0.958206 4.25647 6.25826 4.25647 12C4.25647 16.0027 6.91283 18.9981 9.86786 18.9981C10.1439 18.9981 10.4179 18.9731 10.6879 18.9234C8.70885 17.48 7.4425 14.851 7.4425 12C7.4425 7.46027 10.5135 3.92257 14.1326 3.92257C18.0796 3.92257 20.8243 7.84227 20.8243 12C20.814 15.0707 19.7193 18.1237 17.7929 20.3764C16.1116 22.3428 13.9339 23.5898 11.5395 23.9854C11.5395 23.9854 11.7392 24.0001 12.0002 24.0001C18.6276 24.0001 24 18.6274 24 12C24 9.27729 23.0927 6.76693 21.565 4.75358ZM19.7435 12.0001C19.7435 7.99708 17.0872 5.00172 14.1325 5.00172C13.8561 5.00172 13.5825 5.02672 13.3121 5.07672C15.2915 6.52006 16.5575 9.14909 16.5575 12.0001C16.5575 16.5398 13.4868 20.0775 9.86776 20.0775C5.92039 20.0775 3.17603 16.1578 3.17603 12.0001C3.18603 8.92942 4.28104 5.87639 6.20706 3.6237C7.88874 1.65735 10.0664 0.410337 12.4605 0.0146668C12.4605 0.0146668 12.2611 0 12.0001 0C5.37272 0 0 5.37272 0 12.0001C0 14.7225 0.907342 17.2328 2.43536 19.2469C4.25171 21.5705 6.91873 23.0419 9.86776 23.0419C15.5712 23.0419 19.7435 17.7418 19.7435 12.0001Z"
        fill={theme.text}
      />
    </svg>
  )
}

export default Oasis
