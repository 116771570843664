import useTheme from 'hooks/useTheme'

function EthW() {
  const theme = useTheme()
  return (
    <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M18.0217 13.6689L18 13.7051V13.6762L18.0217 13.6689Z" fill="url(#paint0_linear_106_7776)" />
      <path
        d="M29.0391 20.0954C28.9758 20.1868 22.8164 29.0603 19.7297 33.511C18.6961 35.0017 18.007 35.9931 18 35.9931L6.96094 20.0884L18 26.4657L29.0391 20.0954Z"
        fill={theme.text}
      />
      <path
        d="M28.596 17.3883H28.6171L24.1453 10.0547L23.8921 9.63984L18 0C18 0 16.5234 2.40469 15.0539 4.81641C13.5843 7.22109 12.1148 9.63281 12.1148 9.63281L11.8617 10.0477L7.38979 17.3742L7.02417 17.9719L7.46011 18.225L12.3328 21.0094L12.4031 21.0516L12.6 21.1711L12.6351 21.1922L17.9929 24.2578L23.3507 21.1992L23.3859 21.1781L23.5828 21.0586L23.6531 21.0164L28.5257 18.232L28.9617 17.9789L28.596 17.3813V17.3883ZM8.07886 17.9578C8.07886 17.9508 8.07886 17.9578 8.07886 17.9578V17.9578Z"
        fill={theme.text}
      />
      <path
        d="M24.1032 11.0814L23.7376 19.3713C24.2438 19.5541 24.6024 20.0393 24.6024 20.6088C24.6024 21.1783 24.0118 21.9307 23.2806 21.9307C22.5493 21.9307 21.9587 21.34 21.9587 20.6088C21.9587 19.8775 22.0853 19.9549 22.3032 19.7229L18.5415 14.4494C18.3798 14.5197 18.197 14.5619 18.0071 14.5619C17.8173 14.5619 17.6345 14.5197 17.4728 14.4494L13.704 19.7088C13.922 19.9408 14.0485 20.2572 14.0485 20.6018C14.0485 21.333 13.4579 21.9236 12.7267 21.9236C11.9954 21.9236 11.4048 21.333 11.4048 20.6018C11.4048 19.8705 11.7634 19.5541 12.2626 19.3643L11.904 11.0744C11.3134 10.9408 10.8774 10.4135 10.8774 9.78769C10.8774 9.16191 11.4681 8.46582 12.1993 8.46582C12.9306 8.46582 13.5212 9.05645 13.5212 9.78769C13.5212 10.5189 13.0571 10.9689 12.4524 11.0885L12.804 19.2869C12.9728 19.2939 13.1274 19.3361 13.2681 19.3994L17.0298 14.14C16.8118 13.901 16.6782 13.5916 16.6782 13.2471C16.6782 12.5158 17.2688 11.9252 18.0001 11.9252C18.7313 11.9252 19.322 12.5158 19.322 13.2471C19.322 13.9783 19.1884 13.908 18.9704 14.1471L22.7321 19.4135C22.8728 19.3502 23.0274 19.308 23.1892 19.301L23.5478 11.1025C22.936 10.983 22.479 10.4486 22.479 9.80176C22.479 9.15488 23.0696 8.47988 23.8009 8.47988C24.5321 8.47988 25.1228 9.07051 25.1228 9.80176C25.1228 10.533 24.6868 10.9549 24.0962 11.0885L24.1032 11.0814Z"
        fill={theme.textReverse}
      />
      <defs>
        <linearGradient
          id="paint0_linear_106_7776"
          x1="11.1567"
          y1="8.51641"
          x2="28.6015"
          y2="21.6681"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#BEB8DB" />
          <stop offset="1" stopColor="#6C81C0" />
        </linearGradient>
      </defs>
    </svg>
  )
}

export default EthW
