const LightModeIconActive = ({ width, height }: { width?: number; height?: number }) => {
  return (
    <svg width={width || 14} height={height || 14} viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7 12.065C7.35 12.065 7.63 12.345 7.63 12.695V13.306C7.63 13.656 7.35 13.936 7 13.936H6.994C6.644 13.936 6.364 13.656 6.364 13.306V12.696C6.364 12.346 6.644 12.066 6.994 12.066H7V12.065ZM11.677 10.755L11.925 11.003C12.174 11.251 12.174 11.652 11.925 11.9C11.677 12.148 11.276 12.148 11.028 11.9L10.78 11.652C10.532 11.404 10.532 11.009 10.786 10.755C11.028 10.513 11.429 10.506 11.677 10.755ZM3.207 10.748L3.214 10.755C3.455 10.996 3.462 11.397 3.214 11.645L2.965 11.894C2.717 12.142 2.316 12.142 2.068 11.894C1.82 11.645 1.82 11.251 2.062 10.996L2.31 10.748C2.558 10.5 2.96 10.5 3.207 10.748ZM7 3.15C9.106 3.15 10.818 4.862 10.818 6.968C10.818 9.075 9.106 10.786 7 10.786C4.894 10.786 3.182 9.075 3.182 6.968C3.182 4.862 4.894 3.15 7 3.15ZM7 4.455C5.594 4.455 4.455 5.595 4.455 7C4.455 8.406 5.595 9.545 7 9.545C8.406 9.545 9.545 8.405 9.545 7C9.545 5.594 8.405 4.455 7 4.455ZM1.28 6.332C1.63 6.332 1.91 6.612 1.91 6.968V6.975C1.91 7.325 1.63 7.605 1.273 7.598H0.63C0.28 7.598 0 7.318 0 6.968V6.962C0 6.612 0.28 6.332 0.63 6.332H1.28ZM13.364 6.332C13.714 6.332 13.994 6.612 13.994 6.962V6.968C13.994 7.318 13.714 7.598 13.364 7.598H12.721C12.371 7.598 12.091 7.318 12.091 6.968V6.962C12.091 6.612 12.371 6.332 12.721 6.332H13.364ZM2.965 2.036L3.214 2.285C3.462 2.526 3.462 2.934 3.214 3.175L3.207 3.182C2.959 3.43 2.565 3.43 2.317 3.182L2.068 2.934C1.82 2.692 1.82 2.291 2.068 2.043L2.075 2.036C2.316 1.795 2.717 1.788 2.965 2.036ZM11.932 2.043C12.18 2.291 12.18 2.685 11.932 2.933L11.684 3.182C11.442 3.43 11.041 3.43 10.793 3.182L10.786 3.175C10.538 2.934 10.538 2.533 10.786 2.285L11.035 2.036C11.283 1.795 11.684 1.795 11.932 2.043ZM7.006 0C7.356 0 7.636 0.28 7.636 0.63V1.247C7.636 1.597 7.356 1.877 7 1.871H6.994C6.644 1.871 6.364 1.591 6.364 1.241V0.63C6.364 0.28 6.644 0 7 0H7.006Z"
        fill="white"
      />
    </svg>
  )
}

export default LightModeIconActive
