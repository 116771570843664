import useTheme from 'hooks/useTheme'

function Solana({ size }: { size?: number }) {
  const theme = useTheme()
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36" fill="none">
      <path
        d="M35.8115 26.885L29.8685 33.2573C29.74 33.3957 29.5843 33.5061 29.4112 33.5817C29.2381 33.6573 29.0513 33.6964 28.8624 33.6966H0.690493C0.55613 33.6965 0.424701 33.6573 0.312302 33.5836C0.199906 33.51 0.111419 33.4052 0.0576747 33.2821C0.00393009 33.1589 -0.0127383 33.0228 0.00971203 32.8903C0.0321624 32.7578 0.0927521 32.6348 0.184069 32.5362L6.12029 26.164C6.24884 26.0256 6.40449 25.9151 6.57762 25.8395C6.75071 25.764 6.93755 25.7248 7.12643 25.7246H35.2983C35.4338 25.7218 35.5673 25.7592 35.6817 25.832C35.796 25.9049 35.8866 26.0099 35.9413 26.1339C35.9963 26.258 36.013 26.3955 35.9899 26.5292C35.9668 26.6628 35.9047 26.7866 35.8115 26.885ZM29.8685 14.05C29.7395 13.9123 29.5837 13.8023 29.4107 13.7267C29.2378 13.6512 29.0512 13.6117 28.8624 13.6107H0.690493C0.55613 13.6108 0.424701 13.65 0.312302 13.7237C0.199906 13.7973 0.111419 13.9021 0.0576747 14.0253C0.00393009 14.1484 -0.0127383 14.2845 0.00971203 14.417C0.0321624 14.5495 0.0927521 14.6726 0.184069 14.7711L6.12029 21.1467C6.24931 21.2845 6.4051 21.3945 6.57809 21.4701C6.75108 21.5456 6.93768 21.5851 7.12643 21.586H35.2983C35.4325 21.5853 35.5633 21.5456 35.6753 21.4717C35.7873 21.3978 35.8752 21.293 35.9285 21.17C35.9818 21.047 35.9983 20.9111 35.9755 20.7789C35.953 20.6467 35.8926 20.524 35.8014 20.4256L29.8685 14.05ZM0.690493 9.47211H28.8624C29.0513 9.47194 29.2381 9.4328 29.4112 9.35721C29.5843 9.28165 29.74 9.17117 29.8685 9.03276L35.8115 2.66056C35.9047 2.56213 35.9668 2.43831 35.9899 2.30466C36.013 2.17102 35.9963 2.03352 35.9413 1.90948C35.8866 1.78543 35.796 1.68039 35.6817 1.60755C35.5673 1.53471 35.4338 1.49735 35.2983 1.50015H7.12643C6.93755 1.50035 6.75071 1.53946 6.57762 1.61505C6.40449 1.69064 6.24884 1.80108 6.12029 1.93949L0.184069 8.3117C0.0927521 8.41026 0.0321624 8.53335 0.00971203 8.66582C-0.0127383 8.7983 0.00393009 8.93443 0.0576747 9.05758C0.111419 9.18073 0.199906 9.28554 0.312302 9.35915C0.424701 9.4328 0.55613 9.47204 0.690493 9.47211Z"
        fill={theme.text}
      />
    </svg>
  )
}

export default Solana
