function MoneyFill({ size, color }: { size?: number; color?: string }) {
  return (
    <svg width={size || 16} height={size || 16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.99998 1.3335C4.31998 1.3335 1.33331 4.32016 1.33331 8.00016C1.33331 11.6802 4.31998 14.6668 7.99998 14.6668C11.68 14.6668 14.6666 11.6802 14.6666 8.00016C14.6666 4.32016 11.68 1.3335 7.99998 1.3335ZM8.58665 11.8402V12.0802C8.58665 12.4002 8.32665 12.6668 7.99998 12.6668C7.67998 12.6668 7.41331 12.4068 7.41331 12.0802V11.8002C6.99331 11.7002 6.12665 11.3935 5.61998 10.4002C5.46665 10.1068 5.61331 9.74016 5.91998 9.6135L5.96665 9.5935C6.23998 9.48016 6.54665 9.5935 6.68665 9.8535C6.89998 10.2602 7.31998 10.7668 8.09998 10.7668C8.71998 10.7668 9.41998 10.4468 9.41998 9.6935C9.41998 9.0535 8.95331 8.72016 7.89998 8.34016C7.16665 8.08016 5.66665 7.6535 5.66665 6.1335C5.66665 6.06683 5.67331 4.5335 7.41331 4.16016V3.92016C7.41331 3.5935 7.67998 3.3335 7.99998 3.3335C8.31998 3.3335 8.58665 3.5935 8.58665 3.92016V4.16683C9.29998 4.2935 9.75331 4.6735 10.0266 5.0335C10.2533 5.32683 10.1333 5.7535 9.78665 5.90016C9.54665 6.00016 9.26665 5.92016 9.10665 5.7135C8.91998 5.46016 8.58665 5.20016 8.03998 5.20016C7.57331 5.20016 6.83331 5.44683 6.83331 6.12683C6.83331 6.76016 7.40665 7.00016 8.59331 7.3935C10.1933 7.94683 10.6 8.76016 10.6 9.6935C10.6 11.4468 8.93331 11.7802 8.58665 11.8402Z"
        fill={color || 'currentColor'}
      />
    </svg>
  )
}

export default MoneyFill
