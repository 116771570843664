function Drop({ size = 64 }: { size?: number }) {
  return (
    <svg width={size} height={size} viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_2111_61627)">
        <path
          d="M37.9114 7.05605C36.4335 4.88261 35.1294 2.88305 33.9123 1.05737C33.7384 0.796557 33.4776 0.535745 33.2168 0.36187C32.1736 -0.333629 30.7826 0.0141206 30.0871 1.05737C28.9569 2.88305 27.6528 4.79568 26.088 7.05605C19.2199 17.1408 8.9613 32.3548 8.9613 41.0486C8.9613 47.395 11.5694 53.1329 15.7424 57.3059C19.9154 61.3919 25.6533 64 31.9997 64C38.3461 64 44.084 61.3919 48.257 57.2189C52.43 53.0459 55.0381 47.3081 55.0381 40.9616C55.0381 32.2679 44.7795 17.1408 37.9114 7.05605ZM45.1272 54.0892C41.7367 57.4797 37.129 59.4793 31.9997 59.4793C26.8704 59.4793 22.2627 57.3928 18.8722 54.0892C15.4816 50.6986 13.482 46.0909 13.482 40.9616C13.482 33.6589 23.306 19.1403 29.8263 9.4903C30.6087 8.36011 31.3042 7.22992 31.9997 6.27361C32.6952 7.22992 33.3907 8.36011 34.1731 9.4903C40.6934 19.2273 50.5173 33.6589 50.5173 40.9616C50.5173 46.0909 48.4308 50.6986 45.1272 54.0892Z"
          fill="#27A27E"
        />
        <path
          d="M45.4752 39.3968C44.2581 39.3099 43.2148 40.2662 43.1279 41.4833C43.0409 43.3959 42.4324 45.2216 41.4761 46.7865C40.5197 48.3513 39.1287 49.7423 37.4769 50.6117C36.4337 51.2203 35.999 52.6113 36.6076 53.6545C37.3031 54.8716 38.6941 55.2194 39.7373 54.6108C42.0846 53.3068 43.9972 51.3941 45.3013 49.2207C46.6923 47.0473 47.4747 44.4392 47.5617 41.7441C47.6486 40.527 46.6923 39.4837 45.4752 39.3968Z"
          fill="#31CB9E"
        />
      </g>
      <defs>
        <clipPath id="clip0_2111_61627">
          <rect width="64" height="64" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default Drop
