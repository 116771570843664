import React, { CSSProperties } from 'react'

function OptimismLogoFull({ style = {} }: { style?: CSSProperties }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="150" height="20" viewBox="0 0 150 20" fill="none" style={style}>
      <path
        d="M7.57408 20C5.34861 20 3.52522 19.4815 2.10391 18.4444C0.701303 17.3889 0 15.8889 0 13.9444C0 13.537 0.0467535 13.037 0.140261 12.4444C0.383379 11.1111 0.729356 9.50926 1.17819 7.63889C2.44989 2.5463 5.73199 0 11.0245 0C12.4645 0 13.7549 0.240741 14.8957 0.722223C16.0365 1.18519 16.9341 1.88889 17.5887 2.83333C18.2432 3.75926 18.5705 4.87037 18.5705 6.16667C18.5705 6.55556 18.5238 7.0463 18.4303 7.63889C18.1497 9.28704 17.8131 10.8889 17.4204 12.4444C16.7658 14.9815 15.6344 16.8796 14.0261 18.1389C12.4177 19.3796 10.2671 20 7.57408 20ZM7.96681 16C9.01409 16 9.9024 15.6944 10.6318 15.0833C11.3798 14.4722 11.9128 13.537 12.2307 12.2778C12.6609 10.537 12.9881 9.01852 13.2126 7.72222C13.2874 7.33333 13.3248 6.93518 13.3248 6.52778C13.3248 4.84259 12.4364 4 10.6598 4C9.61253 4 8.71486 4.30556 7.96681 4.91667C7.23745 5.52778 6.71381 6.46296 6.39589 7.72222C6.05926 8.96296 5.72264 10.4815 5.38601 12.2778C5.3112 12.6481 5.2738 13.037 5.2738 13.4444C5.2738 15.1481 6.17147 16 7.96681 16Z"
        fill="#FF0420"
      />
      <path
        d="M21.417 19.7222C21.2112 19.7222 21.0523 19.6574 20.9401 19.5278C20.8466 19.3796 20.8185 19.213 20.8559 19.0278L24.7271 0.972223C24.7645 0.768518 24.8674 0.601852 25.0357 0.472223C25.204 0.342593 25.3817 0.277778 25.5687 0.277778H33.0305C35.1064 0.277778 36.7708 0.703703 38.0238 1.55556C39.2955 2.40741 39.9314 3.63889 39.9314 5.25C39.9314 5.71296 39.8753 6.19444 39.7631 6.69444C39.2955 8.82407 38.3511 10.3981 36.9298 11.4167C35.5272 12.4352 33.6009 12.9444 31.151 12.9444H27.364L26.0736 19.0278C26.0362 19.2315 25.9333 19.3981 25.765 19.5278C25.5967 19.6574 25.4191 19.7222 25.232 19.7222H21.417ZM31.3474 9.11111C32.1329 9.11111 32.8155 8.89815 33.3952 8.47222C33.9937 8.0463 34.3864 7.43518 34.5734 6.63889C34.6295 6.32407 34.6576 6.0463 34.6576 5.80555C34.6576 5.26852 34.4986 4.86111 34.1807 4.58333C33.8628 4.28704 33.3204 4.13889 32.5537 4.13889H29.1874L28.1214 9.11111H31.3474Z"
        fill="#FF0420"
      />
      <path
        d="M46.2519 19.7222C46.0462 19.7222 45.8872 19.6574 45.775 19.5278C45.6628 19.3796 45.6254 19.213 45.6628 19.0278L48.7766 4.63889H43.7833C43.5776 4.63889 43.4186 4.57407 43.3064 4.44445C43.2129 4.2963 43.1849 4.12963 43.2223 3.94444L43.8675 0.972223C43.9049 0.768518 44.0077 0.601852 44.176 0.472223C44.3443 0.342593 44.522 0.277778 44.709 0.277778H59.8011C60.0068 0.277778 60.1657 0.342593 60.278 0.472223C60.3528 0.583334 60.3902 0.694444 60.3902 0.805555C60.3902 0.861111 60.3808 0.916667 60.3621 0.972223L59.7169 3.94444C59.6795 4.14815 59.5767 4.31482 59.4083 4.44445C59.2587 4.57407 59.0811 4.63889 58.8754 4.63889H53.9101L50.7963 19.0278C50.7589 19.2315 50.6561 19.3981 50.4878 19.5278C50.3194 19.6574 50.1418 19.7222 49.9548 19.7222H46.2519Z"
        fill="#FF0420"
      />
      <path
        d="M61.1057 19.7222C60.9 19.7222 60.7411 19.6574 60.6289 19.5278C60.5166 19.3796 60.4792 19.213 60.5166 19.0278L64.4159 0.972223C64.4533 0.768518 64.5562 0.601852 64.7245 0.472223C64.8928 0.342593 65.0704 0.277778 65.2575 0.277778H69.0445C69.2502 0.277778 69.4092 0.342593 69.5214 0.472223C69.5962 0.583334 69.6336 0.694444 69.6336 0.805555C69.6336 0.861111 69.6242 0.916667 69.6055 0.972223L65.7343 19.0278C65.6969 19.2315 65.5941 19.3981 65.4258 19.5278C65.2575 19.6574 65.0798 19.7222 64.8928 19.7222H61.1057Z"
        fill="#FF0420"
      />
      <path
        d="M71.7873 19.7222C71.5816 19.7222 71.4226 19.6574 71.3104 19.5278C71.1982 19.3981 71.1608 19.2315 71.1982 19.0278L75.0694 0.972223C75.1068 0.768518 75.2096 0.601852 75.3779 0.472223C75.5463 0.342593 75.7333 0.277778 75.939 0.277778H78.9686C79.4175 0.277778 79.7073 0.481481 79.8382 0.888889L82.8959 9.63889L89.7126 0.888889C89.8061 0.740741 89.937 0.601852 90.1053 0.472223C90.2923 0.342593 90.5168 0.277778 90.7786 0.277778H93.8363C94.042 0.277778 94.2009 0.342593 94.3131 0.472223C94.3879 0.583334 94.4254 0.694444 94.4254 0.805555C94.4254 0.861111 94.416 0.916667 94.3973 0.972223L90.5261 19.0278C90.4887 19.2315 90.3858 19.3981 90.2175 19.5278C90.0492 19.6574 89.8716 19.7222 89.6845 19.7222H86.2902C86.0845 19.7222 85.9256 19.6574 85.8133 19.5278C85.7198 19.3796 85.6918 19.213 85.7292 19.0278L87.9173 8.77778L83.6253 14.4167C83.4383 14.6204 83.2606 14.7778 83.0923 14.8889C82.924 15 82.7276 15.0556 82.5032 15.0556H81.0445C80.6518 15.0556 80.3806 14.8426 80.231 14.4167L78.2393 8.52778L75.9951 19.0278C75.9577 19.2315 75.8548 19.3981 75.6865 19.5278C75.5182 19.6574 75.3405 19.7222 75.1535 19.7222H71.7873Z"
        fill="#FF0420"
      />
      <path
        d="M96.5484 19.7222C96.3426 19.7222 96.1837 19.6574 96.0715 19.5278C95.9593 19.3796 95.9219 19.213 95.9593 19.0278L99.8585 0.972223C99.8959 0.768518 99.9988 0.601852 100.167 0.472223C100.335 0.342593 100.513 0.277778 100.7 0.277778H104.487C104.693 0.277778 104.852 0.342593 104.964 0.472223C105.039 0.583334 105.076 0.694444 105.076 0.805555C105.076 0.861111 105.067 0.916667 105.048 0.972223L101.177 19.0278C101.14 19.2315 101.037 19.3981 100.868 19.5278C100.7 19.6574 100.522 19.7222 100.335 19.7222H96.5484Z"
        fill="#FF0420"
      />
      <path
        d="M114.159 20C112.513 20 111.101 19.7778 109.923 19.3333C108.763 18.8889 107.884 18.2963 107.286 17.5556C106.706 16.8148 106.416 15.9907 106.416 15.0833C106.416 14.9352 106.435 14.713 106.472 14.4167C106.51 14.25 106.585 14.1111 106.697 14C106.828 13.8889 106.977 13.8333 107.146 13.8333H110.736C110.961 13.8333 111.129 13.8704 111.241 13.9444C111.372 14.0185 111.503 14.1481 111.634 14.3333C111.765 14.8148 112.064 15.213 112.532 15.5278C113.018 15.8426 113.682 16 114.523 16C115.589 16 116.45 15.8333 117.104 15.5C117.759 15.1667 118.152 14.713 118.282 14.1389C118.301 14.0648 118.31 13.9537 118.31 13.8056C118.31 13.3426 118.021 12.9815 117.441 12.7222C116.861 12.4444 115.795 12.1204 114.243 11.75C112.429 11.3611 111.054 10.7778 110.119 10C109.184 9.2037 108.717 8.16667 108.717 6.88889C108.717 6.44444 108.773 5.99074 108.885 5.52778C109.259 3.80556 110.185 2.4537 111.662 1.47222C113.158 0.490741 115.084 0 117.441 0C118.862 0 120.125 0.25 121.228 0.75C122.331 1.25 123.182 1.88889 123.781 2.66667C124.398 3.42593 124.706 4.18519 124.706 4.94445C124.706 5.16667 124.697 5.32407 124.678 5.41667C124.641 5.58333 124.557 5.72222 124.426 5.83333C124.314 5.94445 124.173 6 124.005 6H120.246C119.835 6 119.554 5.84259 119.405 5.52778C119.386 5.10185 119.161 4.74074 118.731 4.44445C118.301 4.14815 117.74 4 117.048 4C116.225 4 115.543 4.14815 115 4.44445C114.477 4.74074 114.149 5.17593 114.019 5.75C114 5.84259 113.99 5.96296 113.99 6.11111C113.99 6.55556 114.252 6.92593 114.776 7.22222C115.318 7.5 116.263 7.78704 117.609 8.08333C119.704 8.49074 121.228 9.07407 122.182 9.83333C123.154 10.5926 123.64 11.6019 123.64 12.8611C123.64 13.287 123.584 13.75 123.472 14.25C123.061 16.1389 122.06 17.5741 120.47 18.5556C118.9 19.5185 116.796 20 114.159 20Z"
        fill="#FF0420"
      />
      <path
        d="M127.362 19.7222C127.156 19.7222 126.997 19.6574 126.885 19.5278C126.773 19.3981 126.735 19.2315 126.773 19.0278L130.644 0.972223C130.681 0.768518 130.784 0.601852 130.953 0.472223C131.121 0.342593 131.308 0.277778 131.514 0.277778H134.543C134.992 0.277778 135.282 0.481481 135.413 0.888889L138.471 9.63889L145.287 0.888889C145.381 0.740741 145.512 0.601852 145.68 0.472223C145.867 0.342593 146.091 0.277778 146.353 0.277778H149.411C149.617 0.277778 149.776 0.342593 149.888 0.472223C149.963 0.583334 150 0.694444 150 0.805555C150 0.861111 149.991 0.916667 149.972 0.972223L146.101 19.0278C146.063 19.2315 145.96 19.3981 145.792 19.5278C145.624 19.6574 145.446 19.7222 145.259 19.7222H141.865C141.659 19.7222 141.5 19.6574 141.388 19.5278C141.294 19.3796 141.266 19.213 141.304 19.0278L143.492 8.77778L139.2 14.4167C139.013 14.6204 138.835 14.7778 138.667 14.8889C138.499 15 138.302 15.0556 138.078 15.0556H136.619C136.226 15.0556 135.955 14.8426 135.806 14.4167L133.814 8.52778L131.57 19.0278C131.532 19.2315 131.429 19.3981 131.261 19.5278C131.093 19.6574 130.915 19.7222 130.728 19.7222H127.362Z"
        fill="#FF0420"
      />
    </svg>
  )
}

export default OptimismLogoFull
